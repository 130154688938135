<template>
  <div :class="{ disabled: disabled }">
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-card class="card" title="业务信息" :bordered="false">
        <div slot="extra">
          <a-affix
            :offsetTop="50"
            :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right' }"
          >
            <a-button><router-link :to="{ name: 'DomesticTrade' }">取消</router-link></a-button>
            <a-divider type="vertical" />
            <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
          </a-affix>
        </div>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="订单编号">
              <a-input v-model="form['serial_num']" disabled placeholder="保存后自动生成" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="报价编号" prop="quota_num">
              <a-input v-model="form['quota_num']" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="委托单位">
              <a-select
                showSearch
                label-in-value
                :filter-option="false"
                allowClear
                placeholder="请选择委托单位"
                style="width: 100%"
                :value="customerInfo"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="客户业务编号">
              <a-input v-model="form['customer_num']" />
            </a-form-model-item>
          </a-col>
          <a-col :xxl="12" :lg="14" :md="16">
            <a-form-model-item label="服务类型" prop="service_types">
              <a-checkbox-group :options="serverTypeOps" v-model="form['service_types']">
                <span slot="label" slot-scope="option">{{ option.name }}</span>
              </a-checkbox-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="订舱代理">
              <a-select
                showSearch
                allowClear
                placeholder="请选择订舱代理"
                style="width: 100%"
                ref="agent"
                v-model="form['agent']"
              >
                <a-select-option v-for="op in agentOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="船公司">
              <a-auto-complete
                v-model="form['trans_company']"
                style="width: 100%"
                placeholder="请选择船公司"
                :filterOption="handleAutoSearch"
              >
                <template slot="dataSource">
                  <a-select-option v-for="op in transCompanyOps" :key="op.name">{{ op.name }}</a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="合约号">
              <a-input v-model="form['deal_num']" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card class="card pc" title="配舱信息" :bordered="false">
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="起运港">
              <a-auto-complete
                v-model="form['departure']"
                style="width: 100%"
                placeholder="请选择起运港"
                :filterOption="handleAutoSearch"
              >
                <template slot="dataSource">
                  <a-select-option v-for="op in setOffOps" :key="op.name">{{ op.name }}</a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="目的港">
              <a-auto-complete
                v-model="form['destination']"
                style="width: 100%"
                placeholder="请选择目的港"
                :filterOption="handleAutoSearch"
              >
                <template slot="dataSource">
                  <a-select-option v-for="op in destOps" :key="op.name">{{ op.name }}</a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="ETD">
              <a-date-picker v-model="form['etd']" class="full-width" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :xxl="18" :lg="21" :md="24">
            <a-form-model-item label="箱型箱量">
              <box-item-group :field="box_info" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="主单号">
              <a-input v-model="form['order_num']" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="船名">
              <a-auto-complete
                v-model="form['ship_name']"
                style="width: 100%"
                placeholder="请选择船名"
                :filterOption="handleAutoSearch"
              >
                <template slot="dataSource">
                  <a-select-option v-for="op in shipOps" :key="op.name">{{ op.name }}</a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="航次">
              <a-input v-model="form['ship_num']" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="码头">
              <a-auto-complete
                v-model="form['port']"
                style="width: 100%"
                placeholder="请选择码头"
                :filterOption="handleAutoSearch"
              >
                <template slot="dataSource">
                  <a-select-option v-for="op in portOps" :key="op.name">{{ op.name }}</a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card class="card" title="提单信息" :bordered="false">
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="发货人">
              <a-input v-model="form['sender']" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="收货人">
              <a-input v-model="form['receiver']" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="通知人">
              <a-input v-model="form['reminders']" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="中文品名">
              <a-input v-model="form['name']" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20" type="flex" justify="start">
          <a-col v-bind="colSpan">
            <a-form-model-item label="总件数">
              <a-input-group compact>
                <a-form-model-item style="width: 40%">
                  <a-input-number style="width:100%" v-model="form['total_num']" />
                </a-form-model-item>
                <a-form-model-item style="width: 60%">
                  <a-auto-complete
                    v-model="form['unit']"
                    style="width:100%"
                    placeholder="请选择包装方式"
                    :filterOption="handleAutoSearch"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in packageTypeOps" :key="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-form-model-item>
              </a-input-group>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="总毛重">
              <a-input suffix="KGS" v-model="form['weight']" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="总体积">
              <a-input suffix="m³" v-model="form['volume']" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="总天数">
              <a-input v-model="form['total_day']" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="付款方式">
              <a-select
                showSearch
                allowClear
                placeholder="请选择付款方式"
                style="width: 100%"
                v-model="form['pay_type']"
              >
                <a-select-option v-for="op in payTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="运输条款">
              <a-auto-complete
                v-model="form['trans_term']"
                style="width: 100%"
                placeholder="请选择运输条款"
                :filterOption="handleAutoSearch"
              >
                <template slot="dataSource">
                  <a-select-option v-for="op in transTermOps" :key="op.name">{{ op.name }}</a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-row :gutter="20">
        <a-col :span="12">
          <a-card class="card" title="订舱备注" :bordered="false">
            <a-form-model-item label="">
              <a-textarea v-model="form['order_remark']" />
            </a-form-model-item>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card class="card" title="配舱备注" :bordered="false">
            <a-form-model-item label="">
              <a-textarea v-model="form['match_remark']" />
            </a-form-model-item>
          </a-card>
        </a-col>
      </a-row>
      <a-card class="card" title="费用信息" :bordered="false">
        <a-tabs default-active-key="a">
          <a-tab-pane key="a" tab="应收费用">
            <edit-table
              scroll
              :columns="chargeFeeColumns"
              :sourceData="chargeFeeData"
              :feeStatusFlag="true"
              @getTableDate="getChargeFeeData"
              @getTableDateError="getTableDateError"
            ></edit-table>
          </a-tab-pane>
          <a-tab-pane key="b" tab="应付费用">
            <edit-table
              scroll
              ref="order_pay_fee"
              :columns="payFeeColumns"
              :sourceData="payFeeData"
              :feeStatusFlag="true"
              @getTableDate="getPayFeeData"
              @getTableDateError="getTableDateError"
            ></edit-table>
          </a-tab-pane>
          <template slot="tabBarExtraContent">
            <template>
              <span style="margin-right: 20px">应收：{{ totalChargeFee.toFixed(2) }} </span>
              <span style="margin-right: 20px"> 应付：{{ totalPayFee.toFixed(2) }} </span>
              <span style="margin-right: 100px"> 利润：{{ (totalChargeFee - totalPayFee).toFixed(2) }} </span>
              <!--<a-button type="primary" :disabled="!isEdit">
                现金收费
              </a-button>-->
            </template>
          </template>
        </a-tabs>
      </a-card>
    </a-form-model>
  </div>
</template>

<script>
import { getCommonOptions, getCustomer, getSupplier } from '@/api/common'
import { getItmsMetaOption, createItmsOrderInfo, getItmsOrderDetail } from '@/api/itms'
import { EditTable, BoxItemGroup } from '@/components'
import { customizeAssign } from '@/utils/util'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce'
export default {
  components: {
    EditTable,
    BoxItemGroup,
    preventReClick
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      customerInfo: undefined,
      fetching: false,
      colSpan: {
        xxl: 6,
        lg: 7,
        md: 8
      },
      disabled: false,
      ship_num: '', // 用来处理航次
      isEdit: false,
      box_info: [{ id: null, num: null, box_num: '' }],
      sourceForm: {
        id: null,
        business_type: 8,
        serial_num: '',
        quota_num: '',
        auth_agency: null,
        customer_num: '',
        trans_type: 1,
        service_types: [],
        agent: null,
        trans_company: '',
        deal_num: '',
        // 配舱信息
        departure: '',
        destination: '',
        etd: null,
        order_num: '',
        ship_name: '',
        // trans_num: [],
        port: '',
        sender: '',
        receiver: '',
        reminders: '',
        name: '',
        total_num: null,
        unit: '',
        weight: null,
        total_day: '',
        volume: null,
        pay_type: null,
        trans_term: '',
        agent_bu_aboard: '',
        order_remark: '',
        match_remark: ''
      },
      form: {},
      agencyOps: [],
      agentOps: [],
      payTypeOps: [
        { value: 1, name: 'FREIGHT_PREPAID' },
        { value: 2, name: 'FREIGHT_COLLECT' }
      ],
      transTermOps: [{ value: 1, name: 'CY-CY' }],
      transCompanyOps: [],
      setOffOps: [],
      destOps: [],
      shipOps: [],
      portOps: [],
      packageTypeOps: [],
      serverTypeOps: [
        { value: 1, name: '订舱' },
        { value: 2, name: '拖车' },
        { value: 3, name: '内装' },
        { value: 4, name: '报关' },
        { value: 5, name: '海外段' },
        { value: 6, name: '保险' },
        { value: 7, name: '租箱' },
        { value: 8, name: '熏蒸' },
        { value: 9, name: '买单' },
        { value: 10, name: '办证' },
        { value: 11, name: '制单' }
      ],
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 1 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 1,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预收',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预收', value: 1 },
              { name: '预收', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      payFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 2,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalPay,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_id',
          extra: {
            type: 'select',
            func: getSupplier,
            params: [{ department: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预付', value: 1 },
              { name: '预付', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      // 应收金额统计展示
      totalChargeFee: 0,
      // 应付金额统计展示
      totalPayFee: 0,
      rules: {
        // 报价编号
        quota_num: [{ required: true, message: '请输入报价编号', trigger: 'blur' }],
        // 服务类型
        service_types: [{ required: true, message: '请选择服务类型', trigger: 'blur' }]
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'DomesticTradeCUR') {
        // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create') {
          // 新增
          this.isEdit = false
          this.$route.meta.title = '新增内贸水运订单'
        } else {
          this.disabled = newRoute.query.disabled
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  created() {
    this.handleFormReset()
    this.getBasicData()
    if (this.$route.params.id !== 'create') {
      this.disabled = this.$route.query.disabled !== 'false' && this.$route.query.disabled
      this.getEditData(this.$route.params.id)
    }
  },
  methods: {
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    moment,
    handleFormReset() {
      this.customerInfo = undefined
      this.chargeFeeData = []
      this.payFeeData = []
      this.totalChargeFee = 0
      this.totalPayFee = 0
      this.form = {
        ...this.sourceForm
      }
    },
    getEditData(id) {
      this.isEdit = true
      this.$route.meta.title = '编辑内贸水运订单'
      getItmsOrderDetail({ id: id }).then(v => {
        const form = { ...this.sourceForm }
        customizeAssign(form, {
          ...v.order,
          ...v.content,
          id: v.order.id,
          etd: v.content['etd'] ? moment(v.content['etd'], 'YYYY/MM/DD') : null
        })
        this.customerInfo = {
          key: v.order.auth_agency,
          label: v.order.agency_name
        }
        v.charge_fees.forEach(fee => {
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        v.pay_fees.forEach(fee => {
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        this.box_info = v.order['box_info'] || [{ id: null, num: null, box_num: '' }]
        this.form = form
        this.ship_num = (v.content.trans_num && v.content.trans_num[0]) || ''
        this.chargeFeeData = v.charge_fees
        this.chargeFeeData.forEach(v => {
          this.totalChargeFee += parseFloat(v.money)
        })
        this.payFeeData = v.pay_fees
        this.payFeeData.forEach(v => {
          this.totalPayFee += parseFloat(v.money)
        })
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.agencyOps = []
      this.fetching = true
      getCustomer({
        department: 2,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.agencyOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['auth_agency'] = value.key
      this.form['agency_name'] = value.label
      this.agencyOps = []
      this.fetching = false
    },
    getBasicData() {
      // getCommonOptions('customer').then(v => {
      //   this.agencyOps = v
      // })
      getCommonOptions('common_type', { db_type: 3 }).then(v => {
        this.packageTypeOps = v
      })
      // 订舱代理
      getItmsMetaOption('itms_role', { role_type: 1 }).then(v => {
        this.agentOps = v
      })
      //  船公司
      getItmsMetaOption('itms_trans_com', { type: 4 }).then(v => {
        this.transCompanyOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 1 }).then(v => {
        this.setOffOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 2 }).then(v => {
        this.destOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 5 }).then(v => {
        this.shipOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 3 }).then(v => {
        this.portOps = v
      })
      // 贸易条款
      getItmsMetaOption('itms_trans_com', { type: 9 }).then(v => {
        this.tradeTermOps = v
      })
      // 付款方式
      getItmsMetaOption('itms_trans_com', { type: 6 }).then(v => {
        this.payTypeOps = v
      })
      // 运输条款
      getItmsMetaOption('itms_trans_com', { type: 11 }).then(v => {
        this.transTermOps = v
      })
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    calcTotalCharge(value, newData) {
      let money = 0
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalChargeFee = money
      return newData
    },
    calcTotalPay(value, newData) {
      let money = 0
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalPayFee = money
      return newData
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const orderKeys = [
            'id',
            'business_type',
            'serial_num',
            'quota_num',
            'auth_agency',
            'agency_name',
            'customer_num',
            'trans_type',
            'service_types',
            'agent',
            'agent_name',
            'trans_company',
            'deal_num',
            'order_num',
            'box_info'
          ]
          const contentKeys = [
            'departure',
            'departure_name',
            'destination',
            'destination_name',
            'etd',
            'ship_name',
            'trans_num',
            'port',
            'sender',
            'receiver',
            'reminders',
            'name',
            'total_num',
            'unit',
            'weight',
            'total_day',
            'volume',
            'pay_type',
            'trans_term',
            'agent_bu_aboard',
            'order_remark',
            'match_remark'
          ]
          const form = this.form
          // customizeAssign(form, formValues)
          const tempData = {
            ...form,
            box_info: this.box_info,
            etd: form.etd ? moment(form.etd).valueOf() : null,
            trans_num: this.ship_num ? [this.ship_num] : [],
            agent_name: form.agent ? this.$refs['agent'].$el.innerText : ''
          }
          const orderData = {}
          const contentData = {}
          for (const key in tempData) {
            if (orderKeys.includes(key)) {
              orderData[key] = tempData[key]
            }
            if (contentKeys.includes(key)) {
              contentData[key] = tempData[key]
            }
          }
          const data = {
            order: orderData,
            content: contentData,
            charge_fees: this.chargeFeeData,
            pay_fees: this.payFeeData
          }
          createItmsOrderInfo('save_main', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            this.$router.push({
              name: 'DomesticTrade',
              params: {
                order: orderData,
                content: contentData
              },
              query: {
                isEdit: this.isEdit
              }
            })
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 10px;
}
.table-title {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 800;
  /* color: #1890ff */
}
.box-item {
  position: relative;
  width: 200px;
  margin-right: 10px;
  margin-top: 10px;
  color: red;
}
.box-item:last-child {
  width: 300px;
}
.box-item_del {
  position: absolute;
  top: -5px;
  left: 190px;
  color: #ccc;
  z-index: 999;
  cursor: pointer;
}
.box-item_del:hover {
  color: #1890ff;
}
</style>
